
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import DomainDropdown from '@/modules/domain/components/domain-dropdown.vue'
import { ShareModule } from '@/modules/share/store'

interface SidebarNavigationItem {
  icon: string
  name: string
  to: RawLocation | string
}

@Component({
  components: {
    DomainDropdown
  },
  name: 'LandscapeSidebarNavigationItems'
})
export default class extends Vue {
  shareModule = getModule(ShareModule, this.$store)

  @Prop({ type: Boolean }) readonly expanded?: boolean

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentTeamId () {
    return this.$queryValue('team')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get navigation (): SidebarNavigationItem[] {
    if (this.currentShareLink) {
      return this.shareNavigation
    }

    return [
      {
        icon: 'fad-house',
        name: 'Overview',
        to: {
          name: 'overview',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-sitemap',
        name: 'Diagrams',
        to: {
          name: 'diagrams',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-list',
        name: 'Model Objects',
        to: {
          name: 'model-objects',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            ...this.$routeName === 'model-objects' ? this.$query : undefined,
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-shuffle',
        name: 'Flows',
        to: {
          name: 'flows',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-arrow-right-arrow-left',
        name: 'Dependencies',
        to: {
          name: 'model-dependencies',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            ...this.$routeName === 'model-dependencies' ? this.$query : undefined,
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-users',
        name: 'Teams',
        to: {
          name: 'teams',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined,
            team: this.currentTeamId || undefined
          }
        }
      },
      {
        icon: 'fad-clock',
        name: 'History',
        to: {
          name: 'history',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      }
    ]
  }

  get shareNavigation (): SidebarNavigationItem[] {
    return [
      {
        icon: 'fad-house',
        name: 'Overview',
        to: {
          name: 'share-overview',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-sitemap',
        name: 'Diagrams',
        to: {
          name: 'share-diagrams',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-list',
        name: 'Model Objects',
        to: {
          name: 'share-model-objects',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            ...this.$routeName === 'share-model-objects' ? this.$query : undefined,
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-shuffle',
        name: 'Flows',
        to: {
          name: 'share-flows',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: 'fad-arrow-right-arrow-left',
        name: 'Dependencies',
        to: {
          name: 'share-model-dependencies',
          params: {
            landscapeId: this.currentLandscapeId || '',
            versionId: this.currentVersionId
          },
          query: {
            ...this.$routeName === 'share-model-dependencies' ? this.$query : undefined,
            domain: this.currentDomainHandleId || undefined
          }
        }
      }
    ]
  }
}
